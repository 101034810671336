$(() => {

    $(document).ready(function() {
        var table = $('#datatable-assets').DataTable({
            autoWidth: true,
            responsive: true,
            order: [[3, 'asc']],
            searching: true,
            language: {
                paginate: {
                    previous: '<i class="fa fa-angle-left"></i>',
                    next: '<i class="fa fa-angle-right"></i>'
                },
                sProcessing: loadingHTML,
                sEmptyTable: "No Records",
                url: "/config/dataTables/lang/" + window.lang + ".json"
            } ,
            autoWidth: false,
            processing: true,
            serverSide: true,
            ajax: {
                url: document.URL,
                createdRow: function(row, data, dataIndex) {

                    $(row).find('td:first').addClass('actions-column');
                }
            },
            columnDefs: [{
                "targets": [7,8],
                "visible": false
                // "searchable": false
            }],

        });

        let vis = 1;
        table.columns().every(function() {
            var column = this;
            if (column.visible()) {
                var select = $('<select><option value=""></option></select>')
                    .appendTo($("#example thead tr.item th:nth-child(" + vis + ")").empty())
                    .on('change', function() {
                        column
                            .search($(this).val())
                            .draw(false);
                    });

                column.data().unique().sort().each(function(d, j) {
                    select.append('<option value="' + d + '">' + d + '</option>');
                });

                vis++;
            };

        });

    });

    // if ($("#datatable-assets").length > 0) {
    //     window.datatable_assets = $("#datatable-assets").DataTable({
    //         autoWidth: true,
    //         responsive: true,
    //         order: [[3, 'asc']],
    //         searching: true,
    //         language: {
    //             paginate: {
    //                 previous: '<i class="fa fa-angle-left"></i>',
    //                 next: '<i class="fa fa-angle-right"></i>'
    //             },
    //             sProcessing: loadingHTML,
    //             sEmptyTable: "No Records",
    //             url: "/config/dataTables/lang/" + window.lang + ".json"
    //         } ,
    //         autoWidth: false,
    //         processing: true,
    //         serverSide: true,
    //         ajax: {
    //             url: document.URL,
    //             createdRow: function(row, data, dataIndex) {

    //                 $(row).find('td:first').addClass('actions-column');
    //             }
    //         },
    //         columnDefs: [{
    //             "targets": [9],
    //             "visible": false
    //             // "searchable": false
    //         }],
    //     });

        // let t = null;

        // $('#datatable-assets').find('thead .filter-col').each((i, el) => {
        //     $(el).on('change keyup', (evt) => {
        //         window.datatable_assets.column(i).search(($(el).is('select') ? $(el).find('option:selected').val() : el.value));
        //         clearTimeout(t);
        //         t = setTimeout(() => {
        //             window.datatable_assets.draw();
        //         }, 500);
        //     });
        // });

        // $('#datatable-calls').find('thead th').each((i, el) => {
        //     $(el).on('click', (evt) => {
        //         if ($(evt.target).is('.filter-col')) {
        //             evt.preventDefault();
        //             evt.stopPropagation();
        //         }
        //     });
        // });
    //}
});
