
const { data } = require("jquery");
const { isSet } = require("lodash");

$(() => {
    if($('#form_interruption_create, #form_interruption_edit').length > 0) {
        const mymap = L.map('interruptionmap');
        var delegationID = -1;
        googleStreets = L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',{
            maxZoom: 20,
            subdomains:['mt0','mt1','mt2','mt3'],
            opacity: 1
            }).addTo(mymap);
        mymap.attributionControl.setPrefix('');
        $.ajax({
            url: '/delegations/coordinates' ,
            data: {'id': delegationID},
            method: 'POST',
            dataType: 'json',
            success: (response) => {
                //console.log(response);
                    mymap.setView(new L.LatLng(response.lat,response.lng), response.zoom);
            }
        });

        mymap.on('click',(e)=>{
            var chosenStreets = document.getElementById('selection-list').getElementsByClassName('selection-card');
            //console.log(chosenStreets[0].getElementsByTagName('address-latitude-text').value);

            let updateStreetLatLng = -1;
            let nameStreet = '';
            for(i=0;i< chosenStreets.length ; i++){
                let latstreet = chosenStreets[i].getElementsByClassName('card-body')[0].lastChild.firstChild;
                if(latstreet == null || latstreet.value == ''){
                    updateStreetLatLng = i;
                    nameStreet = chosenStreets[i].getAttribute("name");
                    var marker = e.target;
                    //chosenStreets[i].getElementsByClassName('card-body')[0].lastChild.firstChild.value = e.latlng.lat;
                    //chosenStreets[i].getElementsByClassName('card-body')[0].lastChild.lastChild.value = e.latlng.lng;
                    chosenStreets[i].getElementsByClassName('card-body')[0].getElementsByTagName('*')[10].value = e.latlng.lat;
                    chosenStreets[i].getElementsByClassName('card-body')[0].getElementsByTagName('*')[11].value = e.latlng.lng;

                    L.marker(e.latlng,{
                        icon: new L.DivIcon({
                            className: 'myDivIcon',
                            iconSize: [100, 0],
                            html: '<div align=\"center\" name=\"'+chosenStreets[i].getAttribute('id')+'\"><i style=\"font-size: 24px; color: blue;\" class=\"fas fa-tint\"></i>'+'<br><span style="background-color: rgb(255 255 255 / 60%);">'+nameStreet+'</span></div>'
                        })
                    }).addTo(mymap).on('click', function(e) {
                            let deleteLatLng = document.getElementById(this._icon.firstElementChild.getAttribute('name'));
                            deleteLatLng.getElementsByClassName('card-body')[0].getElementsByTagName('*')[10].value = '';
                            deleteLatLng.getElementsByClassName('card-body')[0].getElementsByTagName('*')[11].value = '';
                            mymap.removeLayer(this);
                        });
                    break;
                }
            }

        });

        $('#inputDelegation').on('change', function () {
            let delegationID = this.options[this.selectedIndex].value;
            $.ajax({
                url: '/delegations/coordinates' ,
                data: {'id': delegationID},
                method: 'POST',
                dataType: 'json',
                success: (response) => {
                  //  console.log(response);
                        mymap.setView(new L.LatLng(response.lat,response.lng), response.zoom);
                }
            });

        });


    };

    if($('#form_interruption_create, #form_interruption_edit').length > 0) {

        // $("form").trigger("change");

        $('input[name=scheduled]').on('change', (event) => {
            $.ajax({
                url: '/interruptions/get-motive-list',
                data: {'scheduled': event.target.value},
                method: 'POST',
                dataType: 'json',
                success: (response) => {
                    if(response.status == 200){
                        $('#inputMotive').find('option').remove();
                        response.motives.forEach((motive)=>{
                            $('#inputMotive').append(`<option value="${motive.id}">${motive.name}</option>`);
                        })
                    }
                }
            });
        });

        $("form").on('change', function() {
            const formData = $("form").serializeObject();
            const data = {};
            //let _address = '';
            data._token = formData._token[0];
            data.address = formData.address[0];

            let jsonString = String(data.address)
            const obj = JSON.parse(jsonString);

            window.getAffectedAreaText = $.ajax({
                url: '/interruptions/generate_text',
                method: 'POST',
                data: data,
                dataType: 'json',
                success: (data) => {
                    if (data.status === 200) {
                        $("#inputAffectedArea").html(data.text);
                        $("#inputAffectedAreaHidden").val(data.text);

                        /*obj.forEach((str) => {
                            const resourceId = str["data-resource-id"];

                            $.ajax({
                                url: '/interruptions/getInterruptionByLocation/'+resourceId,
                                type: 'GET',
                                success: (response) => {
                                    if(response[0]=='-'){
                                        //$('#currentInterruptionAlert').html(response[1])
                                    } else console.log(response);
                                }
                            });
                        });*/

                    } else {}
                }
            });

        });

        // Date time inputs cant have a date prior to today's date
        // let today = new Date();
        // today.setMinutes(today.getMinutes() - today.getTimezoneOffset());
        // today.setMilliseconds(null);
        // today.setSeconds(null);
        // $('#inputReinstatementDate').attr('min', today.toISOString());
        // $('#inputStartDate').attr('min', today.toISOString());

        //Fill the datetime Initial input with the current date
        // window.addEventListener('load', () => {
        //     const now = new Date();
        //     now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
        //     now.setMilliseconds(null);
        //     now.setSeconds(null);
        //     document.getElementById('startDate').value = now.toISOString().slice(0, -1);
        //     //now.getUTCMonth() + '-'.slice(0, -1);
        // });

        // Validate if the reestablishment date is lower than that start date
        $('form').on('submit', function(e){
            e.preventDefault();
            $('input[type=submit]').prop('disabled', true);
            window.modalWarning.modal('hide');

            let error = false;

            let presentDate = new Date();
            let getStartDate = new Date($('input[name=start_date]').val());
            let setAllowedDate = new Date();
            // allowed start day up to 24h before
            setAllowedDate.setDate(presentDate.getDate() - 1);
            let getReinstatementDate = new Date($('input[name=reinstatement_date]').val());

            let presentDateTotal = (presentDate.getDate() * 24 * 60) + (presentDate.getHours() * 60) + getStartDate.getMinutes();
            let presentDateTotalHours = (presentDate.getDate() * 24 * 60);
            let startDateTotal = (getStartDate.getDate() * 24 * 60) + (getStartDate.getHours() * 60) + getStartDate.getMinutes();
            let startDateTotalHours = (getStartDate.getDate() * 24 * 60);
            let reinstatementDateTotal = (getReinstatementDate.getDate() * 24 * 60)+ (getReinstatementDate.getHours() * 60) + getReinstatementDate.getMinutes();

            if((getReinstatementDate - getStartDate) < 0 ){
                $('#reinstatement_date').parent().popover({
                    html: true,
                    title: function() {
                        return $(document).find('#' + this.id + ' .popover').find('#title').html()
                    },
                    content: function() {
                        return $(document).find('#' + this.id + ' .popover').find('#content').html()
                    },
                });

                $('#reinstatement_date').parent().find('.popover #content').html($('#errors #invalidReestablishmentDate').html());
                $('#reinstatement_date').addClass('border-danger').addClass('bg-flamingo').attr('data-error', true).trigger('focus');
                $('.popover:not(.popover-data)').addClass('popover-danger');
                $('button[type=submit]').prop('disabled', false);
                $("#save-interruption").prop('disabled', false);
                error = true;
            }

            // startdate menor que a data total presente startDateTotalHours < presentDateTotalHours (datediff não funciona)
            else if((getStartDate - setAllowedDate) < 0) {
                $('#start_date').parent().popover({
                    html: true,
                    title: function() {
                        return $(document).find('#' + this.id + ' .popover').find('#title').html()
                    },
                    content: function() {
                        return $(document).find('#' + this.id + ' .popover').find('#content').html()
                    },
                });

                $('#start_date').parent().find('.popover #content').html($('#errors #invalidStartDate').html());
                $('#start_date').addClass('border-danger').addClass('bg-flamingo').attr('data-error', true).trigger('focus');
                $('.popover:not(.popover-data)').addClass('popover-danger');
                $('button[type=submit]').prop('disabled', false);
                $("#save-interruption").prop('disabled', false);
                error = true;
            }

            if (!error)
                this.submit();
        });

        $("#save-interruption").on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            $(this).prop('disabled', true);
            e.bubbles = false;

            $('#modalWarning').find('#type').text($("body").find('label[for="' + $("input[name='scheduled']:checked").attr('id') + '"]').text());
            $('#modalWarning').find('#ref').text($('input[name="work_id"]').val());
            $('#modalWarning').find('#start').text($('input[name="start_date"]').val());
            $('#modalWarning').find('#end').text($('input[name="reinstatement_date"]').val());
            $('#modalWarning').find('#motive').text($('select[name="motive"] option:selected').text());
            $('#modalWarning').find('#area').html($('#inputAffectedArea').html());

            if ($('#form_interruption_edit').length > 0) {
                $('#reviewTable .email-container').removeClass('d-none');
                $('#reviewTable .type-container').addClass('d-none');

                const sendMail = $('input[name="send_mail"]:checked').length > 0;

                console.log(sendMail);

                if (sendMail) {
                    $("#modalWarning").find('#email #yes').removeClass('d-none');
                    $("#modalWarning").find('#email #no').addClass('d-none');
                } else {
                    $("#modalWarning").find('#email #no').removeClass('d-none');
                    $("#modalWarning").find('#email #yes').addClass('d-none');
                }
            }

            window.modalWarning = $($(e.target).attr('data-target')).modal();
        });

        $("#modalWarning").on('hidden.bs.modal', () => {
            $("#save-interruption").prop('disabled', false);
        });

    }
});
